import React from 'react';
import { Layout, Menu, Icon, Affix } from 'antd';
import cx from 'classnames';
import { Link } from 'gatsby';

import Logo from './logo';
import styles from './header.module.less';
import './header.less';

const { Header } = Layout;

const HeaderComponent = ({ site, location }) => {
  return (
    <Affix>
      <Header className={styles.header}>
        <nav className={styles.nav}>
          <Logo src={'/livemenu-logo-bold-blue.svg'} alt={site.name} link='/' />
          <Menu
            className={styles.menu}
            theme='dark'
            mode='horizontal'
            overflowedIndicator={<Icon type='menu' />}
            selectedKeys={[location.pathname]}>
            <Menu.Item key='/partners/'>
              <Link to='/partners/'>Partners</Link>
            </Menu.Item>
            <Menu.Item key='/pilots/'>
              <Link to='/pilots/'>Pilots</Link>
            </Menu.Item>
            <Menu.Item key='/team/'>
              <Link to='/team/'>Team</Link>
            </Menu.Item>
            <Menu.Item key='/about/'>
              <Link to='/about/'>About</Link>
            </Menu.Item>
            {/* <Menu.Item key='/events'>
              <Link to='/events'>Events</Link>
            </Menu.Item> */}
            <Menu.Item key='/contact/'>
              <Link to='/contact/'>Contact</Link>
            </Menu.Item>
            <Menu.Item
              key='/food-for-everyone'
              className={cx(styles.menuItemRight, 'tagline')}>
              <Link to='/food-for-everyone'>
                <span className='heart-icon' />
                <span>Food for Everyone</span>
              </Link>
            </Menu.Item>
          </Menu>
        </nav>
      </Header>
    </Affix>
  );
};

export default HeaderComponent;

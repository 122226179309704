import React from 'react';

import { Layout, Icon } from 'antd';

import styles from './footer.module.less';

const { Footer } = Layout;

const FooterComponent = ({ site }) => (
  <>
    <Footer className={styles.footer}>
      <Icon type='copyright' /> {new Date().getFullYear()} {site.legalName}. All
      rights reserved.
    </Footer>
  </>
);
export default FooterComponent;

import React from 'react';
import { Layout } from 'antd';
import { Location } from '@reach/router';

import Header from './header';
import Footer from './footer';
import FooterLinks from './footer-links';

import styles from './layout.module.less';

const { Content } = Layout;

const MainLayout = ({
  children,
  site = {
    logo: '',
    name: '',
    legalName: '',
  },
}) => (
  <Location>
    {({ location }) => (
      <Layout>
        <Header site={site} location={location} />
        <Content className={styles.content}>
          <div className={styles.typography}>{children}</div>
          <FooterLinks />
        </Content>
        <Footer site={site} />
      </Layout>
    )}
  </Location>
);

export default MainLayout;

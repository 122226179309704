import React from 'react';
import cx from 'classnames';

import styles from './page.module.less';
import './page.less';

const Page = ({ children }) => (
  <div id='page' className={styles.page}>
    {children}
  </div>
);
const Section = ({ children, color, className }) => (
  <div className={cx(styles.section, styles[color], className)}>
    <Wrapper>{children}</Wrapper>
  </div>
);
const Wrapper = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

export { styles, Page, Section, Wrapper };

export default Page;

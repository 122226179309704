import React from 'react';
import styles from './logo.module.less';
import { Link } from 'gatsby';

const Logo = ({ src, alt, link }) => (
  <Link to={link} className={styles.logo}>
    <img className={styles.image} src={src} alt={alt} />
  </Link>
);

export default Logo;

import React from 'react';

import styles from './polaroids.module.less';

const Polaroids = ({ images, padding }) => {
  const style = {
    padding: padding || '64px 0',
  };

  return (
    <div className={styles.polaroids} style={style}>
      {images.map(image => (
        <div key={image.title} title={image.title} className={styles.frame}>
          <img
            className={styles.image}
            src={image.src}
            width={image.width || 270}
            height={image.height || 180}
            alt={image.title}
            title={image.title}
          />
        </div>
      ))}
    </div>
  );
};

export default Polaroids;

import React from 'react';
import { graphql } from 'gatsby';

import SEO from '../components/content/seo';
import Layout from '../containers/layout';
import Team from '../components/pages/team';

const seo = {
  title: 'Team',
  description: 'Team',
  keywords: ['team'],
};

const TeamPage = props => {
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <Team images={props.data} />
    </Layout>
  );
};

export default TeamPage;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment fixedImageWide on File {
    childImageSharp {
      fixed(width: 300) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  fragment fixedImageTall on File {
    childImageSharp {
      fixed(height: 300) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    image1: file(relativePath: { eq: "jeff-cooking.jpg" }) {
      ...fixedImageWide
    }
    image2: file(relativePath: { eq: "looking-at-you.jpg" }) {
      ...fixedImageWide
    }
    image3: file(relativePath: { eq: "sweet-vibes.jpg" }) {
      ...fixedImageTall
    }
    image4: file(relativePath: { eq: "paul-tree.jpg" }) {
      ...fixedImageWide
    }
    image5: file(relativePath: { eq: "paul-sommelier.jpg" }) {
      ...fixedImageTall
    }
    image6: file(relativePath: { eq: "paul-opening-bottle.jpg" }) {
      ...fixedImageWide
    }
    image7: file(relativePath: { eq: "justin-fishing.jpg" }) {
      ...fixedImageWide
    }
    image8: file(relativePath: { eq: "justin-things.jpg" }) {
      ...fixedImageTall
    }

    headshotJeff: file(relativePath: { eq: "jeff-headshot.jpg" }) {
      ...fixedImageWide
    }
    headshotKarise: file(relativePath: { eq: "karise-headshot.jpg" }) {
      ...fixedImageWide
    }
    headshotPaul: file(relativePath: { eq: "paul-headshot.jpg" }) {
      ...fixedImageWide
    }
    headshotJustin: file(relativePath: { eq: "justin-headshot.jpg" }) {
      ...fixedImageWide
    }
    headshotRichard: file(relativePath: { eq: "richard-headshot.jpg" }) {
      ...fixedImageWide
    }
    headshotAndrew: file(relativePath: { eq: "andrew-headshot.jpg" }) {
      ...fixedImageWide
    }
  }
`;

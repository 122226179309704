import React from 'react';

import styles from './bio.module.less';

const Bio = ({ image, name, title, subtitle, children }) => (
  <div className={styles.bio}>
    <div className={styles.image}>
      <img src={image} alt={`${name} - ${title}`} />
    </div>
    <h2 className={styles.name}>{name}</h2>
    <h3 className={styles.title}>{title}</h3>
    <h3 className={styles.subtitle}>{subtitle}</h3>

    <div className={styles.body}>
      <div>{children}</div>
    </div>
  </div>
);

export default Bio;

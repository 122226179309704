import React from 'react';
import { Row, Col } from 'antd';
import { shuffle } from 'lodash';

import { Page, Section } from '../layout/page';
import Bio from '../layout/bio';
import Headline from '../layout/headline';
import Polaroids from '../layout/polaroids';

const Team = ({ images }) => {
  const polaroids = [
    {
      title: 'He Cooks and He Knows Things',
      src: images.image1.childImageSharp.fixed.src,
    },
    {
      title: `Sugar & Spice & Everything Nice`,
      src: images.image3.childImageSharp.fixed.src,
      height: 270,
    },
    {
      title: `Here's Looking at You, Kid`,
      src: images.image2.childImageSharp.fixed.src,
    },
    {
      title: `Sowing the Seeds of Love`,
      src: images.image4.childImageSharp.fixed.src,
      height: 220,
    },
    {
      title: `I Found... This`,
      src: images.image5.childImageSharp.fixed.src,
      height: 270,
    },
    {
      title: `You Want Me to Open It Too?`,
      src: images.image6.childImageSharp.fixed.src,
    },
    {
      title: `Here Fishy Fishy Fishy`,
      src: images.image7.childImageSharp.fixed.src,
      height: 220,
    },
    {
      title: `The Things in My Head`,
      src: images.image8.childImageSharp.fixed.src,
      wide: 200,
      height: 267,
    },
  ];

  return (
    <Page>
      <Section color='white'>
        <Row gutter={64} type='flex' justify='end'>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Headline lead='Meet' emphasis='Our Team' background='white' />
            <Polaroids images={shuffle(polaroids)} padding={`32px 0 32px 0`} />
          </Col>
        </Row>
        <Row gutter={64} type='flex' justify='end'>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Bio
              image={images.headshotJeff.childImageSharp.fixed.src}
              name='Jeff Gaynor'
              title='CEO | Cofounder'
              subtitle='Tech Dude | Home Chef'>
              <p>
                With a passion for building practical applications, Jeff has
                excelled as a Web Developer and User Experience Specialist over
                his 20-year career. As Director of Development and Team Lead for
                successful startups in the Twin Cities, Jeff has emerged as a
                talented leader with a broad range of expertise.
              </p>
            </Bio>
            <blockquote>
              I started cooking when I was 5 years old — the same age I got my
              first computer. I've been programming ever since. Sharing food has
              always been a way I connect with people. I think that's why so
              many chefs pursue their passion. I'm excited to bring these worlds
              together to help people connect over food while they help end
              local hunger.
            </blockquote>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Bio
              image={images.headshotKarise.childImageSharp.fixed.src}
              name='Karise Gaynor-Morgan, Esq.'
              title='COO | Cofounder | GC'
              subtitle='Food Writer | Bakestress '>
              <p>
                Karise has a diverse background in many aspects of successful
                business ventures including corporate legal practice, operations
                management, and B2B sales for Thomson Reuters. Karise has been a
                long-time volunteer with Big Brothers / Big Sisters and other
                pro bono endeavors in Minneapolis.
              </p>
            </Bio>
            <blockquote>
              I believe that business not only can be, but should be,
              integrating social impact into growth models to ensure
              sustainability. It is a measure that extends beyond profit margins
              and into the community in which the business must integrate.
            </blockquote>
          </Col>
        </Row>

        <Row gutter={64} type='flex' justify='end'>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Bio
              image={images.headshotPaul.childImageSharp.fixed.src}
              name='Paul Hennessy'
              title='Restaurant Advisor | Co-owner'
              subtitle='Sommelier | Mixologist'>
              <p>
                Bartender, Sommelier, Father, Chef, Cyclist, General Enthusiast
                of all things hospitality. With over 20 years in the service
                industry, Paul Hennessy is always looking to innovate his craft
                and bring the best service to his guests. When he is not
                charming you with wit and wine behind the bar at The Bachelor
                Farmer you can find him on a bike ride around the chain of lakes
                or in the garden.
              </p>
              <blockquote>
                Sharing food is a way to connect with people we don't understand
                or know. We assume that we know how others think and feel based
                on what we think we know. But we don't know who they are until
                we've sat down at a table together, had dinner with them, shared
                time with them, and gotten to know them. It gives us an
                opportunity to share something real &mdash; an experience we
                couldn't have had otherwise.
              </blockquote>
            </Bio>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Bio
              image={images.headshotJustin.childImageSharp.fixed.src}
              name='Justin Chase'
              title='Tech Architect | Co-owner'
              subtitle='Gamer | Home Brewer'>
              <p>
                Justin is seasoned software developer experienced in JavaScript,
                TypeScript, C# and .NET related technologies. He previously
                worked for Microsoft on Internet Explorer developer tools and
                Visual Studio. He's the guy that brought us the &quot;Inspect
                Element&quot; button on Internet Explorer. He's also a proud
                father, a gamer, a home brewer, and a Vikings season ticket
                holder.
              </p>
              <blockquote>
                Food connects me to my home, community, and family. I enjoy
                great fresh food, local farmers markets, and the amazing
                restaurant scene in the Twin Cities. My family and I make
                regular use of our smoker, and we love growing fresh herbs,
                vegetables, and hops in our small home garden and home brew
                operation.
              </blockquote>
            </Bio>
          </Col>
        </Row>

        <Row gutter={64} type='flex' justify='end'>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Bio
              image={images.headshotRichard.childImageSharp.fixed.src}
              name='Richard Dobkin'
              title='Advisor'
              subtitle='Market Analyst'>
              <p>
                Richard is an experienced business professional successful at
                working with manufacturing, retail, and agricultural companies
                around Minneapolis. He helps bring ideas to life by enabling the
                potential and creativity of others. His curiosity and commitment
                to innovation continues to drive better ways to solve problems
                for both corporations and entrepreneurs.
              </p>
            </Bio>
            <blockquote>
              I was looking for opportunities to become more involved with
              initiatives focused on social impact, and I am excited to be a
              part of LiveMenu’s journey.
            </blockquote>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Bio
              image={images.headshotAndrew.childImageSharp.fixed.src}
              name='Andrew Mueller'
              title='Advisor'
              subtitle='Retail Consultant'>
              <p>
                Andrew is an experienced Retail Consultant with a passion for
                projects that generate meaningful social change. He recently
                left his role in predictive analytics at Target Corporation to
                get his MBA from Kellogg, focusing on social impact.
              </p>
            </Bio>
            <blockquote>
              After hearing about LiveMenu, I was interested in the opportunity
              to offer guest insights to restaurants, while also finding a new
              way to support those in our community that are not fortunate
              enough to plan or choose their meals.
            </blockquote>
          </Col>
        </Row>
      </Section>
    </Page>
  );
};

export default Team;
